import React from "react"
import { Script } from "gatsby"
import { stripIndent } from "common-tags"

import { GTM_ID } from "./src/services/gtm"
import AdProvider from "./src/context/ad-context"
import Layouts from "./src/components/layouts"
import AdScript from "./src/components/ad/ad-script"

const META_PIXEL_ID = process.env.GATSBY_META_PIXEL_ID

export const wrapRootElement = AdProvider

export const wrapPageElement = ({ element, props }) => {
  const { pageContext } = props

  return (
    <>
      <Layouts
        layout={pageContext.layout}
        newsletter={pageContext.newsletter}
        fullWidth={pageContext.fullWidth}
      >
        {element}
      </Layouts>

      {/* InMobi Choice. Consent Manager Tag v3.0 (for TCF 2.2) */}
      <Script id="consent-script">
        {stripIndent`
          (function() {
            var host = 'www.sitepoint.com';
            var element = document.createElement('script');
            var firstScript = document.getElementsByTagName('script')[0];
            var url = 'https://cmp.inmobi.com'
              .concat('/choice/', 'DsckBJsAJpkVK', '/', host, '/choice.js?tag_version=V3');
            var uspTries = 0;
            var uspTriesLimit = 3;
            element.async = true;
            element.type = 'text/javascript';
            element.src = url;
          
            firstScript.parentNode.insertBefore(element, firstScript);
          
            function makeStub() {
              var TCF_LOCATOR_NAME = '__tcfapiLocator';
              var queue = [];
              var win = window;
              var cmpFrame;
          
              function addFrame() {
                var doc = win.document;
                var otherCMP = !!(win.frames[TCF_LOCATOR_NAME]);
          
                if (!otherCMP) {
                  if (doc.body) {
                    var iframe = doc.createElement('iframe');
          
                    iframe.style.cssText = 'display:none';
                    iframe.name = TCF_LOCATOR_NAME;
                    doc.body.appendChild(iframe);
                  } else {
                    setTimeout(addFrame, 5);
                  }
                }
                return !otherCMP;
              }
          
              function tcfAPIHandler() {
                var gdprApplies;
                var args = arguments;
          
                if (!args.length) {
                  return queue;
                } else if (args[0] === 'setGdprApplies') {
                  if (
                    args.length > 3 &&
                    args[2] === 2 &&
                    typeof args[3] === 'boolean'
                  ) {
                    gdprApplies = args[3];
                    if (typeof args[2] === 'function') {
                      args[2]('set', true);
                    }
                  }
                } else if (args[0] === 'ping') {
                  var retr = {
                    gdprApplies: gdprApplies,
                    cmpLoaded: false,
                    cmpStatus: 'stub'
                  };
          
                  if (typeof args[2] === 'function') {
                    args[2](retr);
                  }
                } else {
                  if(args[0] === 'init' && typeof args[3] === 'object') {
                    args[3] = Object.assign(args[3], { tag_version: 'V3' });
                  }
                  queue.push(args);
                }
              }
          
              function postMessageEventHandler(event) {
                var msgIsString = typeof event.data === 'string';
                var json = {};
          
                try {
                  if (msgIsString) {
                    json = JSON.parse(event.data);
                  } else {
                    json = event.data;
                  }
                } catch (ignore) {}
          
                var payload = json.__tcfapiCall;
          
                if (payload) {
                  window.__tcfapi(
                    payload.command,
                    payload.version,
                    function(retValue, success) {
                      var returnMsg = {
                        __tcfapiReturn: {
                          returnValue: retValue,
                          success: success,
                          callId: payload.callId
                        }
                      };
                      if (msgIsString) {
                        returnMsg = JSON.stringify(returnMsg);
                      }
                      if (event && event.source && event.source.postMessage) {
                        event.source.postMessage(returnMsg, '*');
                      }
                    },
                    payload.parameter
                  );
                }
              }
          
              while (win) {
                try {
                  if (win.frames[TCF_LOCATOR_NAME]) {
                    cmpFrame = win;
                    break;
                  }
                } catch (ignore) {}
          
                if (win === window.top) {
                  break;
                }
                win = win.parent;
              }
              if (!cmpFrame) {
                addFrame();
                win.__tcfapi = tcfAPIHandler;
                win.addEventListener('message', postMessageEventHandler, false);
              }
            };
          
            makeStub();
          
            var uspStubFunction = function() {
              var arg = arguments;
              if (typeof window.__uspapi !== uspStubFunction) {
                setTimeout(function() {
                  if (typeof window.__uspapi !== 'undefined') {
                    window.__uspapi.apply(window.__uspapi, arg);
                  }
                }, 500);
              }
            };
          
            var checkIfUspIsReady = function() {
              uspTries++;
              if (window.__uspapi === uspStubFunction && uspTries < uspTriesLimit) {
                console.warn('USP is not accessible');
              } else {
                clearInterval(uspInterval);
              }
            };
          
            if (typeof window.__uspapi === 'undefined') {
              window.__uspapi = uspStubFunction;
              var uspInterval = setInterval(checkIfUspIsReady, 6000);
            }
          })();
        `}
      </Script>
      {/* End InMobi Choice. Consent Manager Tag v3.0 (for TCF 2.2) */}

      {/* Google Tag Manager  */}
      <Script id="gtm-script" strategy="idle">
        {stripIndent`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${GTM_ID}');
        `}
      </Script>
      <Script id="gtm-config" strategy="idle">
        {stripIndent`
          window.dataLayer = window.dataLayer || [];
          function gtag() { dataLayer.push(arguments) };
          gtag(${JSON.stringify({
            platform: "gatsby",
          })});
          gtag('js', new Date());
          gtag('config', '${GTM_ID}', { page_path: location ? location.pathname + location.search + location.hash : undefined });
        `}
      </Script>

      {/* Tapfiliate */}
      <Script
        strategy="idle"
        id="tapfiliate-script"
        src="https://script.tapfiliate.com/tapfiliate.js"
      />
      <Script id="tapfiliate-init" strategy="idle">
        {stripIndent`
          (function(t,a,p){t.TapfiliateObject=a;t[a]=t[a]||function(){ (t[a].q=t[a].q||[]).push(arguments)}})(window,'tap');

          tap('create', '10694-267423', { integration: 'javascript' });
          tap('detect');
        `}
      </Script>
      {/* Meta Pixel */}
      <Script id="meta-script" strategy="idle">
        {stripIndent`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${META_PIXEL_ID}');
          fbq('track', 'PageView');
        `}
      </Script>
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${META_PIXEL_ID}&ev=PageView&noscript=1" />`,
        }}
      />
      <AdScript />
    </>
  )
}
