import React from "react"
import { Script } from "gatsby"
import { useAdState } from "../../context/ad-context"

export default function AdScript() {
  const { initialized, adFree } = useAdState()

  /* Only run in non development environment */
  return (
    process.env.NODE_ENV !== "development" &&
    initialized &&
    !adFree && (
      <>
        <Script id="bsa-queue" strategy="idle">
          {`window.optimize = window.optimize || { queue: [] };`}
        </Script>
        <Script
          id="bsa-script"
          strategy="idle"
          src={`https://cdn4.buysellads.net/pub/sitepoint.js?${new Date().valueOf() - (new Date().valueOf() % 600000)}`}
        />
      </>
    )
  )
}
